import { useParams } from "react-router-dom";
import { useModal } from "../../../../contexts/ModalContext";
import { useDialog } from "../../../../contexts/DialogContext";
import TableComplete from "../../../../components/Tables/TableComplete";
import { Circle } from "../../../../components/Animation";
import { H2, H3 } from "../../../../components/Text";
import { FillForm, SendProcedure } from "../../../../containers/steps";
import { Request } from "../../../../hooks/Request";
import { validateArray } from "../../../../utils/validate";
import dayjs from "dayjs";
import { Button } from "../../../../components/Buttons";
import { useNavigate } from "react-router-dom";

const FolderStep = () => {
  const { rolId, procedureId } = useParams();
  const { openModal, onClose } = useModal();
  const { openDialog } = useDialog();
  const navigate = useNavigate();

  const { data, loading, call } = Request({
    urlApi: `/procedure/${procedureId}/general`,
  });

  function handleStepForm(step) {
    // (step.startDate && step.startDate !== null) ||
    // (step.finishDate && step.finishDate !== null)
    //   ?
    openModal(
      <FillForm
        procedure={{
          id: data.procedureId,
          processStepId: step.processStepId,
          category: data.category,
        }}
        reload={call}
        onClose={onClose}
        openModal={openModal}
        rolId={rolId}
        rols={step.rols}
        // step={rol === "accountant" ? "4" : "3"}
        step={step.step}
        typeId={data.processStepId}
        handleSendProcedure={handleSendProcedure}
      />
    );
    // : openDialog(
    //     <div className="mb-5 text-center">
    //       <h2 className="text-xl">
    //         Debes llenar el paso en progreso primero
    //       </h2>
    //       <h3 className="">
    //         Paso en progreso:{" "}
    //         <span className="font-medium">{data.processStepName}</span>{" "}
    //       </h3>
    //     </div>
    //   );
  }

  function handleSendProcedure(procedure, reloadTable) {
    openModal(
      <SendProcedure
        openModal={openModal}
        procedure={procedure}
        reload={reloadTable}
        onClose={onClose}
      />
    );
  }

  const handleBack = () => {
    navigate(-1);
  };

  if (!loading) {
    return <Circle />;
  }

  const SINdnCod = data.listItems.map((objeto) => objeto.siNdnCod).join(", ");
  const OPCarImpItmDscCom = data.listItems
    .map((objeto) => objeto.opCarImpItmDscCom)
    .join(", ");

  return (
    <>
      <div className="flex bg-blue-100 px-2 py-2 mb-2 rounded-sm gap-2">
        <Button onClick={handleBack}>
          <i className="fas fa-angle-left"></i>
        </Button>
        <div className="w-1/3">
          <h3 className="mb-2">
            <span className="font-medium">Nro. interno:</span> {data.nroInterno}
          </h3>
          <h3 className="mb-2">
            <span className="font-medium">Fecha de inicio:</span>{" "}
            {dayjs(data.fechaInicio).format("DD/MM/YYYY HH:mm")}
          </h3>
          <h3 className="mb-2">
            <span className="font-medium">Nro. DIM/DEX:</span>{" "}
            {data.nroDim || "  ---"}
          </h3>
          <h3 className="mb-2">
            <span className="font-medium">Descripción partida:</span>{" "}
            {OPCarImpItmDscCom || " ---"}
          </h3>
        </div>
        <div className="w-1/3">
          <h3 className="mb-2">
            <span className="font-medium">Paso actual:</span>{" "}
            {`${data.step}. ${data.stepName}`}
          </h3>
          <h3 className="mb-2">
            <span className="font-medium">Cliente:</span>{" "}
            {data.companyName || "  ---"}
          </h3>
          <h3 className="mb-2">
            <span className="font-medium">Referencia cliente:</span>{" "}
            {data.refCliente || "  ---"}
          </h3>
        </div>
        <div className="w-1/3">
          <h3 className="mb-2">
            <span className="font-medium">Mercadería:</span>{" "}
            {data.mercaderia || "  ---"}
          </h3>
          <h3 className="mb-2">
            <span className="font-medium">Partida arancelaria:</span>{" "}
            {SINdnCod || "  ---"}
          </h3>
          <h3 className="mb-2">
            <span className="font-medium">Aduana:</span>{" "}
            {data.aduana || "  ---"}
          </h3>
        </div>
      </div>

      <TableComplete
        // data={data}
        // call={call}
        // height={currentSize.height ? currentSize.height : null}
        // buttons={buttons}
        data={data.steps}
        onClick={handleStepForm}
        urlApi={`/procedure/${procedureId}/general`}
        header={[
          { name: "step", label: "Nro" },
          { name: "processStepName", label: "Paso" },
          {
            name: "startDate",
            label: "Estado",
            type: "custom",
            builder: (value, _, data) => {
              return (
                <div
                  className={`w-fit text-white ${
                    data.finishDate && data.finishDate !== null
                      ? "bg-green-500 text-white"
                      : // ? "bg-[#4fce4431] text-gray-500"
                      value
                      ? "bg-blue-500"
                      : "bg-[#74747431] text-gray-500"
                  } text-center rounded-full py-1 px-4`}
                >
                  {data.finishDate && data.finishDate !== null
                    ? "Completado"
                    : value
                    ? "En progreso"
                    : "No iniciado"}
                </div>
              );
            },
          },
          { name: "startDate", label: "Inicio", type: "date" },
          { name: "finishDate", label: "Fin", type: "date" },
          {
            name: "rols",
            label: "Roles",
            type: "custom",
            builder: (rols) => {
              return (
                <div className="flex flex-col gap-1">
                  {rols.map((rol) => (
                    <div
                      className="w-fit border-2 border-gray-500 rounded-md px-1"
                      key={rol.id}
                    >
                      {rol.label}
                    </div>
                  ))}
                </div>
              );
            },
          },
        ]}
      />
    </>
  );
};

export default FolderStep;
