import React, { useState } from "react";
import Select from "react-select";
import { useAuth } from "../../contexts/AuthContext";
import { Request } from "../../hooks/Request";
import { H1 } from "../../components/Text";
import { H3 } from "../../components/Text";
import Texto from "../../components/Text/Texto";
import Circle from "../../components/Animation/Circle";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDialog } from "../../contexts/DialogContext";
import { requestAuth } from "../../components/services/RequestService";
import { SimpleTableManagement } from "../../containers/Systems/TableManagement";
import { ChartContainer } from "../../components/Chart";
import { filtersDefaultChart } from "../../utils/constant";

const Summary = () => {
  const { user } = useAuth();

  return (
    <div className="bg-slate-200 h-full p-[10px] sm:p-[20px]">
      {user?.company?.length === 0 ? null : (
        <div className="bg-white m-[20px] p-[10px] sm:p-[20px] flex flex-col items-center">
          {/* {user?.company?.length === 1 ? (
            <H1>INFORMACIÓN DE LA COMPAÑÍA</H1>
          ) : (
            <H1>INFORMACIÓN DE LAS COMPAÑÍAS</H1>
          )} */}
          {user?.company?.map((company, index) => (
            <CompanySection key={index} company={company} />
          ))}
        </div>
      )}
      <div className="bg-white m-[20px] p-[10px] sm:p-[20px] flex flex-col items-center">
        <H1>INFORMACIÓN DE USUARIO</H1>
        <div className="w-full flex flex-wrap items-center justify-items-center content-center sm:justify-between">
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2">NOMBRE</H3>
            <Texto className="border-b-2">{user?.user?.name}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2">APELLIDO PATERNO</H3>
            <Texto className="border-b-2">{user?.user?.fatherLastName}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> APELLIDO MATERNO</H3>
            <Texto className="border-b-2">{user?.user?.motherLastName}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2">CARNET DE IDENTIDAD</H3>
            <Texto className="border-b-2">{user?.user?.ci}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> Nro DE CELULAR </H3>
            <Texto className="border-b-2">{user?.user?.cellphoneNumber}</Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> Nro DE TELEFONO </H3>
            <Texto className="border-b-2">
              {user?.user?.phoneNumber
                ? user.user.phoneNumber
                : "No registrado"}
            </Texto>
          </div>
          <div className="flex flex-col items-center justify-items-center w-full sm:w-1/3 p-[15px] mx-auto my-[10px] sm:my-[15px]">
            <H3 className="border-b-2"> EMAIL </H3>
            <Texto className="border-b-2">{user?.user?.email}</Texto>
          </div>
        </div>
      </div>
    </div>
  );
};

const CompanySection = ({ company }) => {
  const [valueSelect, setvalueSelect] = useState(23);
  const { data, call, loading } = Request({
    urlApi: company.id
      ? `/clientStats/companies/${company.id}?internCode=${valueSelect}`
      : null,
  });

  useEffect(() => {
    call();
  }, [valueSelect]);
  if (!loading) {
    return <Circle />;
  }

  if (data === undefined) return null;
  return (
    <>
      <CompanyCharts companyId={company.id} />
      <div className="flex w-full flex-col items-center border-y-2">
        <InfoEmpresa user={company} />
        {/* <div className="w-[95%] sm:w-[40%] sm:border-l-2 px-[20px]">
        <H3 className="py-[20px]">GRÁFICA CON EL TOTAL DE PROCESOS</H3>
        <Label htmlFor="año">Filtrar por año</Label>
        <select
          value={valueSelect}
          onChange={(e) => setvalueSelect(e.target.value)}
        >
          <option value="23">2023</option>
          <option value="24">2024</option>
        </select>
        <GraficChart year={valueSelect} values={data} />
      </div> */}
      </div>
    </>
  );
};

const InfoEmpresa = ({ user }) => {
  const { data } = Request({
    urlApi: user.id ? `company/${user?.id}/ClientsCompany` : null,
  });

  function convertData(convertData) {
    let date = new Date(convertData);
    let fecha = "";
    if (
      date.getDate() !== 0 &&
      date.getMonth() !== 0 &&
      date.getFullYear() !== 0
    ) {
      return (fecha =
        date.getDate() + " / " + date.getMonth() + " / " + date.getFullYear());
    }
    return "No registrado";
  }

  return (
    <div className="flex flex-col m-auto">
      <H1>{user?.razonSocial}</H1>
      <div className="w-full flex flex-wrap items-center justify-items-center content-center sm:justify-between">
        {/* <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">RAZÓN SOCIAL</H3>
          <Texto className="border-b-2">{user?.razonSocial}</Texto>
        </div> */}
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">NIT</H3>
          <Texto className="border-b-2">{user?.nit}</Texto>
        </div>
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2"> ESTADO</H3>
          <Texto className="border-b-2">{user?.status}</Texto>
        </div>
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">FECHA DE CREACIÓN</H3>
          <Texto className="border-b-2">
            {convertData(user?.creationDate)}
          </Texto>
        </div>
        <div className="flex flex-col items-center justify-items-center w-full lg:w-1/2 my-[10px] sm:my-[15px] p-[15px] mx-auto">
          <H3 className="border-b-2">CLIENTES HABILITADOS</H3>
          {data?.map((client, index) => {
            return (
              <Texto key={index} className="border-b-2">
                -{client?.name} {client?.fatherLastName}{" "}
                {client?.motherLastName}
              </Texto>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const CompanyCharts = ({ companyId }) => {
  const [year, setYear] = useState("2024");
  const [chartType, setChartType] = useState("Mercadería");
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const location = useLocation();

  const graficas = [
    { value: "Mercadería", label: "Mercadería" },
    { value: "Aduanas De Despachos", label: "Aduanas De Despachos" },
    { value: "Cif", label: "Cif" },
    { value: "Canales", label: "Canales" },
    // { value: "Clientes", label: "Clientes" },
    // { value: "Liquidadores", label: "Liquidadores" },
    // { value: "Gestores", label: "Gestores" },
    // { value: "Comisiones", label: "Comisiones" },
    // { value: "Problemas Del Despacho", label: "Problemas Del Despacho" },
    // { value: "Observaciones Aforo", label: "Observaciones Aforo" },
    // { value: "Examen Previo", label: "Examen Previo" },
    // { value: "Multas", label: "Multas" },
    // { value: "Reintegros", label: "Reintegros" },
    // { value: "Multa Fiscalización", label: "Multa Fiscalización" },
    // { value: "Reintegro Fiscalización", label: "Reintegro Fiscalización" },
    // {
    //   value: "Gestoria Lista De Observaciones",
    //   label: "Gestoria Lista De Observaciones",
    // },
    // {
    //   value: "Contabilidad Lista De Observaciones",
    //   label: "Contabilidad Lista De Observaciones",
    // },
  ];
  const gestiones = [
    { value: "2003", label: "2003" },
    { value: "2004", label: "2004" },
    { value: "2005", label: "2005" },
    { value: "2006", label: "2006" },
    { value: "2007", label: "2007" },
    { value: "2008", label: "2008" },
    { value: "2009", label: "2009" },
    { value: "2010", label: "2010" },
    { value: "2011", label: "2011" },
    { value: "2012", label: "2012" },
    { value: "2013", label: "2013" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ];

  const handleChartTypeChange = (selectedOption) => {
    setChartType(selectedOption.value);
  };

  const handleYearChange = (selectedOption) => {
    setYear(selectedOption.value);
  };

  const ContentData = ({ actualData, selectedData }) => {
    let urlApi = `${actualData.urlApiMain}&value=${actualData.fieldValue}&company=${selectedData.companyId}&month=${actualData.numMonth}&demo=emo`;

    const [data, setData] = useState(null);
    const [load, setLoad] = useState(false);
    useEffect(() => {
      setLoad(true);
      requestAuth("GET", urlApi)
        .then(function (response) {
          setData(response.data);
          setLoad(false);
        })
        .catch(function (error) {
          console.log("error");
          setLoad(false);
        });
    }, [urlApi]);

    const nav = (i) => {
      console.log(location.pathname);
      if (location.pathname.includes("/rol/6/")) {
        navigate(`./step/${i.step}`);
      } else {
        navigate(`/rol/6/menu/ManageProcedures`);
        const timeoutId = setTimeout(() => {
          navigate(`/rol/6/procedure-type/1/step/${i.step}`);
          return () => clearTimeout(timeoutId);
        }, 2500);
      }
      // dialogClose()
    };
    return load ? (
      <Circle spinnerColor="text-orange-400" />
    ) : (
      <SimpleTableManagement
        header={[
          {
            name: "codigoDeInterno",
            label: "Código de interno",
          },
          {
            name: "stepName",
            label: "Nombre de paso",
          },
          {
            name: "edit",
            type: "action",
            label: "Acciones",
            actions: [
              {
                label: "Añadir campo",
                icon: "fas fa-share",
                action: nav,
                color: "text-[#1d4ed8]",
              },
            ],
          },
        ]}
        data={data}
        labelGrafic="trámite"
      />
    );
  };

  function handleClickMonth(
    label,
    data,
    urlApi,
    params,
    generalData,
    urlApiMain
  ) {
    openDialog(
      <div style={{ width: "80vw" }}>
        <ChartContainer
          // title={label}
          title={`${label} - Mes: ${data?.label}`}
          type="SubChart"
          urlApi={`${urlApi}Month`}
          generalData={{
            ...generalData,
            fieldValue: label,
            numMonth: data?.id,
            onClick: console.log(generalData),
          }}
          addParams={{
            dateQuery: "fechaDeFacturación",
            month: data?.id,
            value: label,
            ...params,
          }}
          ContentData={ContentData}
          actualData={{
            ...generalData,
            fieldValue: label,
            numMonth: data?.id,
            urlApiMain: urlApiMain.replace(urlApi, `${urlApi}Table`),
          }}
        />
      </div>
    );
  }
  const monthList = [
    {
      label: "Enero",
      name: "1",
    },
    {
      label: "Febrero",
      name: "2",
    },
    {
      label: "Marzo",
      name: "3",
    },
    {
      label: "Abril",
      name: "4",
    },
    {
      label: "Mayo",
      name: "5",
    },
    {
      label: "Junio",
      name: "6",
    },
    {
      label: "Julio",
      name: "7",
    },
    {
      label: "Agosto",
      name: "8",
    },
    {
      label: "Septiembre",
      name: "9",
    },
    {
      label: "Octubre",
      name: "10",
    },
    {
      label: "Noviembre",
      name: "11",
    },
    {
      label: "Diciembre",
      name: "12",
    },
  ];

  function handleClickClient(label, _, urlApi, params) {
    openDialog(
      <div style={{ width: "80vw" }}>
        <ChartContainer
          title={`Mes: ${label.label}`}
          type="SubChart"
          urlApi={`${urlApi.substring(0, urlApi.length - 6)}Number`}
          addParams={{
            dateQuery: "fechaDeFacturación",
            month: label.id,
            ...params,
          }}
        />
      </div>
    );
  }

  return (
    <div className="w-full">
      <div className="flex gap-4 items-center mb-2">
        <h3>Gráfica de gerencia: </h3>
        <Select
          options={graficas}
          value={graficas.find((option) => option.value === chartType)}
          onChange={handleChartTypeChange}
        />
        <h3>Gestión: {year}</h3>
        <Select
          options={gestiones}
          value={gestiones.find((option) => option.value === year)}
          onChange={handleYearChange}
        />
      </div>
      {
        {
          Mercadería: (
            <ChartContainer
              // onClick={handleClickMonth}
              onClick={() => {}}
              title="Cantidad de tramites por tipo de mercadería"
              urlApi={`/Procedure/field/descripciónDeLaMercancía/year/${year}/chart`}
              // filters={filtersDefaultChart}
              addParams={{
                dateQuery: "a2FechaDeAceptación",
                CompanyId: companyId,
              }}
              tableHeaders={[
                {
                  label: "tipo de mercadería",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          "Aduanas De Despachos": (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por aduana de despacho"
              urlApi={`/Procedure/field/a4AduanaDeDespacho/year/${year}/chart`}
              // filters={filtersDefaultChart}
              addParams={{
                dateQuery: "a2FechaDeAceptación",
                CompanyId: companyId,
              }}
              tableHeaders={[
                {
                  label: "Compañia",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          Cif: (
            <ChartContainer
              onClick={handleClickClient}
              title="Monto de CIF por mes"
              urlApi={`/Procedure/field/f10TotalValorCifAduana/year/${year}/chartAmount`}
              // filters={filtersDefaultChart}
              type="SimpleBar"
              addParams={{
                dateQuery: "a2FechaDeAceptación",
                CompanyId: companyId,
              }}
              amountTableHeaders={[
                {
                  label: "Mes",
                  name: "mes",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Monto",
                  name: "amount",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                // {
                //   label: "Cantidad",
                //   name: "cantidad",
                //   aditionalClass: "font-bold text-gray-500",
                //   isNumber: true,
                //   makeTotal: true,
                // },
              ]}
            />
          ),
          Canales: (
            <ChartContainer
              onClick={handleClickMonth}
              title="Cantidad de tramites por canal"
              urlApi={`/Procedure/field/canal(Rojo,AmarilloOVerde)/year/${year}/chart`}
              // filters={filtersDefaultChart}
              addParams={{
                dateQuery: "a2FechaDeAceptación",
                CompanyId: companyId,
              }}
              tableHeaders={[
                {
                  label: "Canal",
                  name: "option",
                  aditionalClass: "bg-[#ffeadd]",
                },
                {
                  label: "Cantidad",
                  name: "total",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotal: true,
                },
                {
                  label: "Porcentaje %",
                  name: "percentage",
                  aditionalClass: "font-bold text-gray-500",
                  isNumber: true,
                  makeTotalPercentage: true,
                },
              ]}
            />
          ),
          // Clientes: (
          //   <ChartContainer
          //     title="Cantidad de tramites por cliente"
          //     urlApi={`/procedure/company/year/${year}/chart`}
          //     filters={filtersDefaultChart}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Compañia",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
          // Liquidadores: (
          //   <ChartContainer
          //     onClick={handleClickMonth}
          //     title="Cantidad de tramites por liquidador"
          //     urlApi={`/Procedure/field/designacionDeLiquidador/year/${year}/chart`}
          //     generalData={{ year, fieldname: "designacionDeLiquidador" }}
          //     filters={[
          //       ...[
          //         {
          //           name: "procedureType",
          //           url: "/procedureType",
          //           default: "Todos los tramites",
          //           valueOption: "id",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "liquidator",
          //           url: "/Dropdown/rol_liquidator/options",
          //           label: "Todos los liquidadores",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "gestor",
          //           url: "/Dropdown/rol_gestor/options",
          //           label: "Todos los gestores",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "inCharge",
          //           url: "/Dropdown/rol_InCharge/options",
          //           label: "Todos los encargados",
          //           valueOption: "id",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "prioridad",
          //           url: "/Dropdown/prioridad/options",
          //           label: "Prioridad",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "complejidad",
          //           url: "/Dropdown/ComplejidadDelDespacho/options",
          //           label: "Complejidad",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "dateQuery",
          //           label: "Filtrar fecha por: ",
          //           options: [
          //             {
          //               label: "Fecha de facturación",
          //               name: "fechaDeFacturación",
          //             },
          //             {
          //               label: "Fecha aceptación",
          //               name: "a2FechaDeAceptación",
          //             },
          //           ],
          //         },
          //         {
          //           name: "months",
          //           label: "Todos los meses",
          //           options: monthList,
          //         },
          //       ],
          //     ]}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Liquidador",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
          // Gestores: (
          //   <ChartContainer
          //     onClick={handleClickMonth}
          //     title="Cantidad de tramites por gestor"
          //     urlApi={`/Procedure/field/asignarGestor/year/${year}/chart`}
          //     filters={[
          //       ...[
          //         {
          //           name: "procedureType",
          //           url: "/procedureType",
          //           default: "Todos los tramites",
          //           valueOption: "id",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "liquidator",
          //           url: "/Dropdown/rol_liquidator/options",
          //           label: "Todos los liquidadores",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "gestor",
          //           url: "/Dropdown/rol_gestor/options",
          //           label: "Todos los gestores",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "inCharge",
          //           url: "/Dropdown/rol_InCharge/options",
          //           label: "Todos los encargados",
          //           valueOption: "id",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "prioridad",
          //           url: "/Dropdown/prioridad/options",
          //           label: "Prioridad",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "complejidad",
          //           url: "/Dropdown/ComplejidadDelDespacho/options",
          //           label: "Complejidad",
          //           valueOption: "name",
          //           labelOption: "name",
          //         },
          //         {
          //           name: "dateQuery",
          //           label: "Filtrar fecha por:",
          //           // defaultValue: "fechaDeFacturación",
          //           options: [
          //             {
          //               label: "Fecha de facturación",
          //               name: "fechaDeFacturación",
          //             },
          //             {
          //               label: "Fecha aceptación",
          //               name: "a2FechaDeAceptación",
          //             },
          //           ],
          //         },
          //         {
          //           name: "months",
          //           label: "Todos los meses",
          //           options: monthList,
          //         },
          //         // { type: "rangeDate" },
          //       ],
          //     ]}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Gestor",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
          // Comisiones: (
          //   <ChartContainer
          //     onClick={handleClickClient}
          //     title="Monto de comisiones por mes"
          //     urlApi={`/Procedure/field/d-HonorariosProfesionales/year/${year}/chartAmount`}
          //     filters={filtersDefaultChart}
          //     type="SimpleBar"
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     amountTableHeaders={[
          //       {
          //         label: "Mes",
          //         name: "mes",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Monto",
          //         name: "amount",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "cantidad",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //     ]}
          //   />
          // ),
          // "Problemas Del Despacho": (
          //   <ChartContainer
          //     onClick={handleClickMonth}
          //     title="Cantidad de tramites por problemas del despacho"
          //     urlApi={`/Procedure/field/problemasDeDespacho/year/${year}/chart`}
          //     filters={filtersDefaultChart}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Problema del Despacho",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
          // "Observaciones Aforo": (
          //   <ChartContainer
          //     onClick={handleClickMonth}
          //     title="Cantidad de tramites por observaciones aforo"
          //     urlApi={`/Procedure/field/observaciones(Aforo)/year/${year}/chart`}
          //     filters={filtersDefaultChart}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Observación Aforo",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
          // "Examen Previo": (
          //   <ChartContainer
          //     onClick={handleClickMonth}
          //     title="Cantidad de tramites por examen previo"
          //     urlApi={`/Procedure/field/examenPrevioRealizado/year/${year}/chart`}
          //     filters={filtersDefaultChart}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Exámen prévio",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
          // Multas: (
          //   <ChartContainer
          //     onClick={handleClickClient}
          //     title="Monto de multas por mes"
          //     urlApi={`/Procedure/field/montoDeMultaPorContravencion/year/${year}/chartAmount`}
          //     filters={filtersDefaultChart}
          //     type="SimpleBar"
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     amountTableHeaders={[
          //       {
          //         label: "Mes",
          //         name: "mes",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Monto",
          //         name: "amount",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "cantidad",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //     ]}
          //   />
          // ),
          // Reintegros: (
          //   <ChartContainer
          //     onClick={handleClickClient}
          //     title="Monto de reintegros por mes"
          //     urlApi={`/Procedure/field/montoDeReintregro/year/${year}/chartAmount`}
          //     filters={filtersDefaultChart}
          //     type="SimpleBar"
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     amountTableHeaders={[
          //       {
          //         label: "Mes",
          //         name: "mes",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Monto",
          //         name: "amount",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "cantidad",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //     ]}
          //   />
          // ),
          // "Multa Fiscalización": (
          //   <ChartContainer
          //     onClick={handleClickClient}
          //     title="Monto de multa fiscalización por mes"
          //     urlApi={`/Procedure/field/multaPorContravencion(ControlDiferido)/year/${year}/chartAmount`}
          //     filters={filtersDefaultChart}
          //     type="SimpleBar"
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     amountTableHeaders={[
          //       {
          //         label: "Mes",
          //         name: "mes",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Monto",
          //         name: "amount",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "cantidad",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //     ]}
          //   />
          // ),
          // "Reintegro Fiscalización": (
          //   <ChartContainer
          //     onClick={handleClickClient}
          //     title="Monto reintegro fiscalización por mes"
          //     urlApi={`/Procedure/field/montoDeReintegro-ControlDiferido(Bs)/year/${year}/chartAmount`}
          //     filters={filtersDefaultChart}
          //     type="SimpleBar"
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     amountTableHeaders={[
          //       {
          //         label: "Mes",
          //         name: "mes",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Monto",
          //         name: "amount",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "cantidad",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //     ]}
          //   />
          // ),
          // "Gestoria Lista De Observaciones": (
          //   <ChartContainer
          //     onClick={handleClickMonth}
          //     title="Gestoria Lista De Observaciones"
          //     urlApi={`/Procedure/field/gestoriaListaDeObservaciones(ProblemasEnElDespachoConcluido)/year/${year}/chart`}
          //     filters={filtersDefaultChart}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Gestoria Lista De Observaciones",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
          // "Contabilidad Lista De Observaciones": (
          //   <ChartContainer
          //     onClick={handleClickMonth}
          //     title="Contabilidad Lista De Observaciones"
          //     urlApi={`/Procedure/field/contabilidadListaDeObservaciones(ProblemasEnElDespachoConcluido)/year/${year}/chart`}
          //     filters={filtersDefaultChart}
          //     addParams={{ dateQuery: "fechaDeFacturación" }}
          //     tableHeaders={[
          //       {
          //         label: "Contabilidad Lista De Observaciones",
          //         name: "option",
          //         aditionalClass: "bg-[#ffeadd]",
          //       },
          //       {
          //         label: "Cantidad",
          //         name: "total",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotal: true,
          //       },
          //       {
          //         label: "Porcentaje %",
          //         name: "percentage",
          //         aditionalClass: "font-bold text-gray-500",
          //         isNumber: true,
          //         makeTotalPercentage: true,
          //       },
          //     ]}
          //   />
          // ),
        }[chartType]
      }
    </div>
  );
};

export default Summary;
