// import './RenderPdf.css'
import * as dayjs from "dayjs";
import { Request } from "../../hooks/Request";
// import logotipo from "../../assets/LogotipoGA.png";
import { Circle } from "../../components/Animation";
// import { requestAuth } from '../../components/services/RequestService'
import View from "../../components/GeneratePdf/View";
import Text from "../../components/GeneratePdf/Text";
import CustomTablePDF from "../../components/GeneratePdf/CustomTablePDF";
// import GeneratePdf from '../../components/GeneratePdf/GeneratePdf'
import { Page, Document, PDFViewer, Image } from "@react-pdf/renderer";
import qrAgda from "../../assets/qrAgda.png";

const PdfDocumentRdr = (props) => {
  const { lot } = props;
  // console.log("🚀 ~ PdfDocumentRdr ~ lot:", lot);
  const { data, loading } = Request({
    urlApi: `/procedure/${lot.id}/rvd`,
  });
  
  // console.log("🚀.............. ~ PdfDocumentRdr ~ lot:", data);
  // async function uploadFile(blob) {
  //   var file = new File([blob], 'filename.pdf', {
  //     type: 'application/pdf',
  //     lastModified: Date.now(),
  //   })
  //   let formData = new FormData()
  //   formData.append('Name', 'prueba231')
  //   formData.append('File', file)
  //   formData.append('Type', 'File')
  //   formData.append('CompanyId', 1)
  //   console.log(formData)
  //   await requestAuth('post', '/files', formData)
  //     .then((res) => {
  //       console.log(res)
  //     })
  //     .catch(() => {})
  // }

  if (!loading) {
    return <Circle />;
  }
  const MyDoc = (
    <Document>
      <Page size="A4" style={{ padding: "20px" }}>
        <View>
          <View width="20%" justifyContent="center" alignItems="center">
            <Image
              src="https://res.cloudinary.com/joeldes/image/upload/v1703637339/Agda/logo_nw1jah.png"
              style={{ height: "50px", padding: "5px 0" }}
            />
          </View>
          <View
            width="65%"
            justifyContent="center"
            alignItems="center"
            padding="10px"
          >
            <Text textAlign="center" fontSize="11px" bold>
              RECEPCIÓN, DEVOLUCION Y REQUERIMIENTO DOCUMENTAL RDR
            </Text>
          </View>
        </View>

        <View>
          <View width="20%" flexDirection="column" borderContent>
            <Text bold>AGDA S.A.</Text>
          </View>
          <View
            width="80%"
            flexDirection="column"
            borderContent
            style={{ margin: "0 1px" }}
          >
            <Text bold>ARCHIVO CENTRAL</Text>
          </View>
        </View>

        <View>
          <View
            width="70%"
            flexDirection="column"
            borderContent
            style={{ margin: " 1px 1px" }}
          >
            <Text bold>RECEPCION, DEVOLUCION Y REQUERIMIENTO DOCUMENTAL</Text>
          </View>
          <View
            width="10%"
            flexDirection="column"
            borderContent
            style={{ margin: " 1px 1px" }}
          >
            <Text bold>N°</Text>
          </View>
          <View
            width="20%"
            flexDirection="column"
            borderContent
            style={{ margin: " 1px 1px" }}
          >
            <Text bold>{lot.num}</Text>
          </View>
        </View>
        {/* ------------------bloques1-------------------------- */}

        <View>
          <View borderContent style={{ margin: " 1px 1px" }}>
            <Text bold minWidth="10%" border={{ right: true }}>
              PARA:
            </Text>
            <Text minWidth="50%">ARCHIVO</Text>
          </View>
          <View
            // style={{ margin: " 0 1px" }}
            borderContent
            style={{ margin: " 1px 1px" }}
          >
            <Text bold minWidth="10%" border={{ right: true }}>
              FECHA:
            </Text>
            <Text bold minWidth="15%">
              {dayjs(lot.creationDate).format("DD/MM/YYYY")}
            </Text>
          </View>
          <View borderContent style={{ margin: " 1px 1px" }}>
            <Text bold minWidth="15%">
              AREA:
            </Text>
          </View>
        </View>

        <View>
          <View borderContent>
            <Text bold minWidth="10%" border={{ right: true }}>
              DE:
            </Text>
            <Text minWidth="50%">
              {lot.nameUser} {lot.fatherLastNameUser} {lot.motherLastNameUser}
            </Text>
          </View>
          <View style={{ margin: " 0 2px" }} borderContent>
            <Text bold minWidth="10%" border={{ right: true }}>
              HORA:
            </Text>
            <Text bold minWidth="15%" border={{ right: true }}>
              {dayjs(lot.creationDate).format("HH:mm")}
            </Text>
            <Text bold minWidth="15%">
              ARCHIVO
            </Text>
          </View>
        </View>

        {/* ------------------------------------bloques de filas y columnas----------------------- */}
        <View style={{ marginTop: "2px" }}>
          <View>
            <View flexDirection="column" borderContent>
              <View
                style={{ height: "25px" }}
                width="40px"
                border={{ bottom: true }}
              ></View>
              <View style={{ height: "51px", maxWidth: "40px" }}>
                <Text
                  style={{
                    transform: "rotate(-90deg)",
                    top: "0",
                    left: "10px",
                    right: "0",
                    bottom: "5px",
                    position: "relative",
                  }}
                >
                  SOLICITUD DE:
                </Text>
              </View>
            </View>
            <View flexDirection="column" style={{ margin: " 0 1px" }}>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent style={{ paddingBottom: "4px" }}>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
            </View>
          </View>
          <View>
            <View flexDirection="column" borderContent>
              <View
                style={{ height: "25px" }}
                width="40px"
                border={{ bottom: true }}
              ></View>
              <View style={{ height: "51px", maxWidth: "40px" }}>
                <Text
                  style={{
                    transform: "rotate(-90deg)",
                    top: "0",
                    left: "10px",
                    right: "0",
                    bottom: "5px",
                    position: "relative",
                  }}
                >
                  ENTREGA DE:
                </Text>
              </View>
            </View>
            <View flexDirection="column" style={{ margin: " 0 1px" }}>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent style={{ paddingBottom: "4px" }}>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
            </View>
          </View>
          <View>
            <View flexDirection="column" borderContent>
              <View
                style={{ height: "25px" }}
                width="40px"
                border={{ bottom: true }}
              ></View>
              <View style={{ height: "51px", maxWidth: "40px" }}>
                <Text
                  style={{
                    transform: "rotate(-90deg)",
                    top: "0",
                    left: "10px",
                    right: "0",
                    bottom: "5px",
                    position: "relative",
                  }}
                >
                  DEVOLUCION DE:
                </Text>
              </View>
            </View>
            <View flexDirection="column" style={{ margin: " 0 1px" }}>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent style={{ paddingBottom: "4px" }}>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
            </View>
          </View>
          <View>
            <View flexDirection="column" borderContent>
              <View
                style={{ height: "25px" }}
                width="40px"
                border={{ bottom: true }}
              ></View>
              <View style={{ height: "51px", maxWidth: "40px" }}>
                <Text
                  style={{
                    transform: "rotate(-90deg)",
                    top: "0",
                    left: "10px",
                    right: "0",
                    bottom: "5px",
                    position: "relative",
                  }}
                >
                  ESCANEO DE:
                </Text>
              </View>
            </View>
            <View flexDirection="column" style={{ margin: " 0 1px" }}>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
              <View borderContent style={{ paddingBottom: "4px" }}>
                <View border={{ right: true }}>
                  <Text>CARPETAS</Text>
                </View>
                <View width="20px"></View>
              </View>
            </View>
          </View>
          <View borderContent width="20%" flexDirection="column">
            <Text bold>OTRO</Text>
            <Text border={{ top: true }}>
              {/* {dayjs().format("DD/MM/YYYY HH:mm")} */}
              ----
            </Text>
          </View>
        </View>

        {/* ------------------------------bloque de observaciones---------------------- */}

        <View
          borderContent
          padding="20px"
          width="100%"
          flexDirection="column"
          style={{ marginTop: "2px", marginBotton: "2 px" }}
        >
          <View border={{ top: true }}></View>
          <CustomTablePDF
            fields={[
              {
                label: "N°",
                name: "doc",
                type: "index",
                width: "10",
              },
              {
                label: "HCC",
                name: "amount",
                width: "20",
              },
              {
                label: "CARPETA",
                name: "nroInterno",
                width: "20",
              },
              {
                label: "OBSERVACION",
                name: "obs",
                width: "50",
              },
            ]}
            border={{ right: true, bottom: true, left: true }}
            data={lot.lotProcedures}
          />
        </View>

        {/* <View style={{ marginTop: "2px" }}
          border={{ top: "true", right: "true", left: "true" }}
        >
          <Text color="white" bold>
            --------
          </Text>
        </View>
        <View border={{ bottom: "true", right: "true", left: "true" }}>
          <Text bold>
            OBSERVACIONES
          </Text>
        </View> */}

        <View style={{ marginTop: "2px", paddingTop: "20px" }} borderContent>
          <Text bold>OBSERVACIONES</Text>
        </View>

        <View padding="20px" borderContent style={{ marginTop: "2px" }}>
          <View
            width="50%"
            // border={{ right: true }}
            justifyContent="center"
            alignItems="center"
          >
            <Image src={qrAgda} style={{ width: "110px" }} />
          </View>

          <View borderContent width="100%">
            <View flexDirection="column">
              <View border={{ bottom: true }}>
                <View width="50%" border={{ right: true }}>
                  <Text>SALIDA</Text>
                </View>
                <View width="50%">
                  <Text>RECIBO</Text>
                </View>
              </View>
              <Text>INGRESO</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      {/* <BlobProvider document={MyDoc}>
        {({ blob }) => {
          return <div onClick={() => uploadFile(blob)}>Subir documento</div>
        }}
      </BlobProvider> */}
      <PDFViewer
        style={{
          width: 900,
          height: window.innerHeight - 100,
        }}
        children={MyDoc}
      />
    </div>
  );
};

export default PdfDocumentRdr;
