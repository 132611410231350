import CallServiceTable from "../../components/Tables/CallServiceTable";
import { H3, Anchor } from "../../components/Text";
import { useDialog } from "../../contexts/DialogContext";
import { useModal } from "../../contexts/ModalContext";
import TableComplete from "../../components/Tables/TableComplete";
import { Circle } from "../../components/Animation";
import RenderPdf from "../../containers/ManegeProcedure/RenderPdf";
import RenderLiquidaciónProforma from "../../containers/ManegeProcedure/RenderLiquidaciónProforma";
import { useAuth } from "../../contexts/AuthContext";
import { Request } from "../../hooks/Request";
import ListDocument from "../../components/common/ListDocument";
import { headerList } from "../../utils/constant";
import { Button } from "../../components/Buttons";
import { ExportStatementAduana } from "../../containers/ManegeProcedure/ExportStatementAduana";
import GenerateRoadmap from "../../containers/ManegeProcedure/GenerateRoadmap";
import { ImportClearancePdf } from "../../containers/ManegeProcedure/ImportClearancePdf";

const ClientList = () => {
  const { user } = useAuth();
  const { openDialog } = useDialog();
  const { openModal, onClose } = useModal();

  function listDocumentPdf(procedure) {
    openDialog(<RenderPdf procedure={procedure} />);
  }
  function generateSettlementProforma(procedure) {
    openDialog(<RenderLiquidaciónProforma procedure={procedure} />);
  }
  function viewProcedure(procedure) {
    openModal(
      <ProcedureData procedure={procedure} onClose={onClose} user={user} />
    );
  }

  function handleDocument(procedure) {
    openModal(<ListDocument procedure={procedure} deleteDocument={false} />);
  }

  function generateItems() {
    openDialog(
      <ExportStatementAduana
        companyId={user?.company[0]?.id}
        openDialog={openDialog}
      />
    );
  }

  function generateRoadmap(procedure) {
    openDialog(<GenerateRoadmap procedure={procedure} />);
  }

  function generateLiquidation(procedure) {
    openDialog(<ImportClearancePdf procedure={procedure} />);
  }

  if (user?.user) {
    return (
      <div>
        <CallServiceTable
          onClick={viewProcedure}
          component={({ reload }) => {
            return (
              <Button
                position="left"
                content="Generar reporte"
                onClick={() => generateItems(reload)}
              >
                <i className="fas fa-book"></i>
              </Button>
            );
          }}
          urlApi={`procedure/byuserid/${user?.user?.id}/companyId/${user?.company[0]?.id}/pag`}
          addFilters={[
            // {
            //   name: "procedureNumber",
            //   label: "Tramite",
            //   filter: true,
            // },
            {
              name: "internCode",
              label: "Nro. interno",
              filter: true,
            },
            {
              name: "dimNumber",
              label: "Nro. DIM/DEX",
              filter: true,
            },
          ]}
          header={[
            ...headerList,
            {
              name: "Reports",
              type: "action",
              label: "Reportes",
              actions: [
                {
                  label: "Reporte de liquidación",
                  icon: "fas fa-print",
                  action: generateLiquidation,
                  color: "text-[#1d4ed8]",
                },
                {
                  label: "Generar hoja de ruta",
                  icon: "fas fa-route",
                  action: generateRoadmap,
                  color: "text-[#1d4ed8]",
                },
                {
                  label: "Ver documentos",
                  icon: "fa-solid fa-folder",
                  action: handleDocument,
                  color: "text-[#1d4ed8]",
                },
                // {
                //   label: "Generar informe de la recepción de documentos",
                //   icon: "fas fa-tasks",
                //   action: listDocumentPdf,
                //   color: "text-[#1d4ed8]",
                // },
                // {
                //   label: "Generar liquidación - proforma",
                //   icon: "fas fa-coins",
                //   action: generateSettlementProforma,
                //   color: "text-[#1d4ed8]",
                // },
              ],
            },
          ]}
          addUrl={`userId=${localStorage.getItem("id")}`}
          statusPaination={false}
          showTable={true}
        />
      </div>
    );
  } else {
    return <Circle />;
  }
};

const ProcedureData = ({ procedure }) => {
  const { data, loading } = Request({
    urlApi: `/Procedure/numRef/${procedure.internCode}/Detail`,
  });
  if (!loading) {
    return <Circle />;
  }
  // function hadleDocument(list) {
  //   let listDocument = [];
  //   list.forEach((dataSet) => {
  //     if ((dataSet.type = "fileStatus")) {
  //       listDocument = [...listDocument, ...dataSet.fileStates];
  //     } else {
  //       listDocument = [
  //         ...listDocument,
  //         { ...dataSet, url: dataSet.value, name: dataSet.label },
  //       ];
  //     }
  //   });
  //   return listDocument;
  // }

  console.log(data);

  const TextData = ({ title, children }) => {
    if (children) {
      return (
        <div className="flex gap-4 p-2 bg-white mb-2 rounded-md">
          <h3 className="font-semibold">{title}:</h3>
          <h4>{children}</h4>
        </div>
      );
    }
    return null;
  };
  return (
    <div>
      <div className="mb-2 rounded-md p-2">
        <h3 className="text-xl font-semibold text-center mb-3">Datos</h3>
        <TextData title="Carpeta">{data.numRef}</TextData>
        <TextData title="Referencia cliente">{data.referenciaCliente}</TextData>
        <TextData title="Mercadería">{data.mercaderia}</TextData>
        <TextData title="DIM">{data.dim}</TextData>
        <TextData title="Fecha de aceptación">{data.fechaAceptacion}</TextData>
        <TextData title="Fecha de inicio">{data.creationDate}</TextData>
        <TextData title="DIM adjuntada">
          <a
            className="text-blue-600 underline"
            href={data.dimUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            Enlace del documento
          </a>
        </TextData>
        {/* {data.map((item) =>
          item.type !== "fileStatus" && item.type !== "file" ? (
            <div key={item.id} className="flex gap-2 mx-1 my-1">
              <H3>{item.label}:</H3>
              <H3 className="font-normal">{item.value}</H3>
            </div>
          ) : null
        )} */}
      </div>
      {/* <div className="bg-white rounded-md">
        <h3 className="text-xl font-semibold text-center mb-2">Documentos</h3>
        <TableComplete
          header={[
            {
              name: "name",
              label: "Documento",
            },
            {
              name: "url",
              label: "Enlace",
              type: "custom",
              builder: (item) => {
                if (item) {
                  return (
                    <Anchor
                      href={item}
                      className="text-[#1d4ed8] underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Abrir
                    </Anchor>
                  );
                } else {
                  return "-";
                }
              },
            },
            {
              name: "number",
              label: "Nro",
            },

            {
              label: "Fecha",
              name: "creationDate",
              type: "date",
            },
            {
              name: "presentation",
              label: "Presentación",
            },
            {
              name: "status",
              label: "Estado",
            },
            {
              label: "Proveedor",
              name: "provider",
            },
          ]}
          data={hadleDocument(data)}
        />
      </div> */}
    </div>
  );
};

export default ClientList;
